export default {
    "available_periods": {
        "12": "Год"
    },
    "available_sections_for_period": {
        "12": {
            "required": {
                "selected": false
            },
            "functional": {
                "selected": false
            },
            "career": {
                "selected": false
            },
            "growth": {
                "selected": false
            }
        }
    },
    "card_limit_for_user_per_period": 1,
    "cards_create_info_template": "componentsCompany/smz/CardsCreateInfo",
    "medium_facts": {
        "available": false
    },
    "card_recount_bonus_service": null,
    "card_ar_history_behavior_ignore_fields": [],
    "cards_visible_permissions_criteria_map": {
        "card.see_if_participate": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardIParticipateCriteria",
        "card.see_my": "app\\domain\\Card\\criteria\\CardMyCriteria",
        "card.see_subordinates": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardAllSubordinatesCriteria",
        "card.see_chiefs": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardMyChiefsCriteria"
    },
    "cards_search_criteria": [
        "app\\domain\\Card\\criteria\\CardsUserFiredCriteria"
    ],
    "cards_search_expand": [
        "info",
        "user",
        "user.directChief",
        "permissions"
    ],
    "cards_page_filters": {
        "template": "componentsCompany/smz/CardsPageFilters",
        "card_search_request_class": "app\\build\\smz\\card\\criteria\\SmzCardSearchRequest",
        "search_fields": {
            "cards.id": {
                "condition": "=",
                "type": "number"
            },
            "users.last_name": {
                "relation": "user"
            },
            "users.first_name": {
                "relation": "user"
            },
            "users.middle_name": {
                "relation": "user"
            },
            "users.position": {
                "relation": "user"
            },
            "users.subdivision": {
                "relation": "user"
            }
        },
        "default_sort_params": {
            "attributes": {
                "id": {
                    "asc": {
                        "cards.id": 4
                    },
                    "desc": {
                        "cards.id": 3
                    }
                },
                "0": "year",
                "1": "status",
                "user.last_name": {
                    "asc": {
                        "users.last_name": 4
                    },
                    "desc": {
                        "users.last_name": 3
                    }
                },
                "user.position": {
                    "asc": {
                        "users.position": 4
                    },
                    "desc": {
                        "users.position": 3
                    }
                },
                "user.location": {
                    "asc": {
                        "users.location": 4
                    },
                    "desc": {
                        "users.location": 3
                    }
                },
                "user.subdivision": {
                    "asc": {
                        "users.subdivision": 4
                    },
                    "desc": {
                        "users.subdivision": 3
                    }
                },
                "user.workshop": {
                    "asc": {
                        "users.workshop": 4
                    },
                    "desc": {
                        "users.workshop": 3
                    }
                },
                "user.directChief.full_name": {
                    "asc": {
                        "users.last_name": 4
                    },
                    "desc": {
                        "users.last_name": 3
                    }
                },
                "info.evaluate_final_efficiency_id": {
                    "asc": {
                        "card_info.evaluate_final_efficiency_id": 4
                    },
                    "desc": {
                        "card_info.evaluate_final_efficiency_id": 3
                    }
                },
                "info.evaluate_potential_id": {
                    "asc": {
                        "card_info.evaluate_potential_id": 4
                    },
                    "desc": {
                        "card_info.evaluate_potential_id": 3
                    }
                },
                "info.evaluate_risk_dismissal_id": {
                    "asc": {
                        "card_info.evaluate_risk_dismissal_id": 4
                    },
                    "desc": {
                        "card_info.evaluate_risk_dismissal_id": 3
                    }
                },
                "info.evaluate_impact_on_business_id": {
                    "asc": {
                        "card_info.evaluate_impact_on_business_id": 4
                    },
                    "desc": {
                        "card_info.evaluate_impact_on_business_id": 3
                    }
                }
            },
            "defaultOrder": {
                "id": 3
            }
        }
    },
    "cards_page_columns": [
        {
            "label": "ФИО",
            "width": 300,
            "template": "componentsCompany/smz/tableColumnTemplates/cards/RouterLinkToCardColumnTemplate",
            "prop": "user.last_name"
        },
        {
            "label": "Должность",
            "prop": "user.position"
        },
        {
            "label": "Дирекция",
            "prop": "user.direction"
        },
        {
            "label": "Цех",
            "prop": "user.workshop",
            "width": 80
        },
        {
            "label": "ФИО НР",
            "prop": "user.directChief.full_name"
        },
        {
            "label": "Год",
            "prop": "year",
            "width": 80
        },
        {
            "label": "Статус карты целей",
            "width": 200,
            "template": "components/tableColumnTemplates/cards/StatusColumnTemplate",
            "prop": "status"
        },
        {
            "label": "Статус обратной связи",
            "width": 220,
            "template": "componentsCompany/smz/tableColumnTemplates/cards/TotalEvaluationsVisibilityColumnTemplate",
            "on": {
                "load-cards": "(e)  => { templateData.$emit(\"load-cards\", e) }"
            }
        },
        {
            "template": "componentsCompany/smz/tableColumnTemplates/cards/ActionsColumnTemplate",
            "width": 80,
            "align": "right",
            "on": {
                "delete-card": "(e) => { templateData.$emit(\"delete-card\", e) }"
            }
        }
    ],
    "card_header_elements": [
        {
            "label": "Сотрудник",
            "icon": "fas fa-id-card-alt",
            "prop": "user.full_name"
        },
        {
            "label": "Должность",
            "icon": "fas fa-briefcase",
            "prop": "info.position"
        },
        {
            "label": "Бенд",
            "icon": "fas fa-layer-group",
            "prop": "info.band"
        },
        {
            "label": "Дирекция",
            "icon": "fas fa-briefcase",
            "prop": "info.direction"
        },
        {
            "label": "Цех",
            "icon": "fas fa-warehouse",
            "prop": "info.workshop"
        },
        {
            "label": "Возраст",
            "icon": "fas fa-user-clock",
            "prop": "user.age"
        },
        {
            "label": "Стаж",
            "icon": "fas fa-laptop",
            "prop": "info.experience"
        },
        {
            "label": "Непосредственный руководитель",
            "icon": "fas fa-user-tie",
            "prop": "user.chiefs.direct.full_name"
        }
    ],
    "card_selectable_page_columns": []
}