export default {
    "events": {
        "beforeAction": [
            {
                "class": "app\\domain\\User\\handlers\\UserBeforeActionHandler"
            }
        ]
    },
    "chief_types": {
        "direct": {
            "showName": "Непосредственный руководитель"
        },
        "functional": {
            "showName": "Функциональный руководитель"
        },
        "hr_moscow": {
            "showName": "Менеджер по персоналу (Москва)"
        },
        "hr_samara": {
            "showName": "Менеджер по персоналу (Самара)"
        },
        "comp_manager_rss": {
            "showName": "Компенсационный менеджер (РСС)"
        },
        "comp_manager_workers": {
            "showName": "Компенсационный менеджер (Рабочие)"
        }
    },
    "my_chiefs_types": {
        "direct": [],
        "functional": [],
        "hr_moscow": [],
        "hr_samara": []
    },
    "my_employees_types": {
        "direct": [],
        "functional": [],
        "hr_moscow": [],
        "hr_samara": [],
        "comp_manager_rss": [],
        "comp_manager_workers": []
    },
    "users_search_criteria": [
        "app\\domain\\User\\criteria\\UsersFiredCriteria"
    ],
    "users_visible_permissions_criteria_map": {
        "user.see_self": "app\\domain\\User\\criteria\\returnInNewQuery\\UserSelfCriteria",
        "user.see_subordinates": "app\\domain\\User\\criteria\\returnInNewQuery\\UserAllSubordinatesCriteria",
        "user.see_chiefs": "app\\domain\\User\\criteria\\returnInNewQuery\\UserChiefsCriteria"
    },
    "users_list_columns": [
        {
            "label": "Личный номер",
            "prop": "persnum",
            "width": 160
        },
        {
            "label": "ФИО",
            "prop": "full_name"
        },
        {
            "label": "Должность",
            "prop": "position"
        },
        {
            "label": "Цех",
            "prop": "workshop",
            "width": 80
        },
        {
            "label": "Дирекция",
            "prop": "direction"
        },
        {
            "label": "Подразделение",
            "prop": "subdivision"
        },
        {
            "label": "Бенд",
            "prop": "band",
            "width": 80
        }
    ],
    "users_list_default_role_filter_in_search": "user",
    "filters": {
        "users_search_request_class": "app\\build\\smz\\user\\requests\\SmzUserSearchRequest",
        "criteria": {
            "locations": "app\\build\\smz\\user\\criteria\\SmzUserLocationCriteria",
            "subdivisions": "app\\build\\smz\\user\\criteria\\SmzUserSubdivisionCriteria",
            "directions": "app\\build\\smz\\user\\criteria\\SmzUserDirectionCriteria",
            "workshops": "app\\build\\smz\\user\\criteria\\SmzUserWorkshopCriteria",
            "positions": "app\\build\\smz\\user\\criteria\\SmzUserPositionCriteria",
            "bands": "app\\build\\smz\\user\\criteria\\SmzUserBandCriteria",
            "ages": "app\\build\\smz\\user\\criteria\\SmzUserAgeCriteria",
            "direct_chief_user_id": "app\\build\\smz\\user\\criteria\\SmzUserDirectChiefCriteria",
            "position_risks": "app\\build\\smz\\user\\criteria\\SmzUserPositionRiskCriteria",
            "successors": "app\\build\\smz\\user\\criteria\\UserSubordinatesByTreeCriteria",
            "categories": "app\\build\\smz\\user\\criteria\\SmzUserCategoryCriteria"
        }
    },
    "sort_params": {
        "attributes": {
            "0": "persnum",
            "1": "company",
            "2": "email",
            "3": "position",
            "4": "subdivision",
            "5": "company",
            "full_name": {
                "asc": {
                    "last_name": 4
                },
                "desc": {
                    "last_name": 3
                }
            }
        },
        "defaultOrder": {
            "full_name": 4
        }
    },
    "chiefs_list_columns": [
        {
            "label": "Личный номер",
            "prop": "user.persnum",
            "width": 160
        },
        {
            "label": "ФИО",
            "prop": "user.full_name"
        },
        {
            "label": "Должность",
            "prop": "user.position"
        },
        {
            "label": "Цех",
            "prop": "user.workshop",
            "width": 80
        },
        {
            "label": "Дирекция",
            "prop": "user.direction"
        },
        {
            "label": "Подразделение",
            "prop": "user.subdivision"
        },
        {
            "label": "Локация",
            "prop": "user.location",
            "width": 110
        },
        {
            "label": "Руководитель",
            "prop": "chief.full_name"
        }
    ],
    "chiefs_search_fields": {
        "users.last_name": {
            "relation": "user"
        },
        "users.first_name": {
            "relation": "user"
        },
        "users.middle_name": {
            "relation": "user"
        },
        "users.position": {
            "relation": "user"
        },
        "cast(users.workshop as text)": {
            "relation": "user"
        },
        "users.direction": {
            "relation": "user"
        },
        "users.subdivision": {
            "relation": "user"
        },
        "users.location": {
            "relation": "user"
        },
        "chiefs.last_name": {
            "relation": "chief chiefs"
        },
        "chiefs.first_name": {
            "relation": "chief chiefs"
        },
        "chiefs.middle_name": {
            "relation": "chief chiefs"
        }
    },
    "chiefs_sort_params": {
        "attributes": {
            "user.persnum": {
                "asc": {
                    "users.persnum": 4
                },
                "desc": {
                    "users.persnum": 3
                }
            },
            "user.email": {
                "asc": {
                    "users.email": 4
                },
                "desc": {
                    "users.email": 3
                }
            },
            "user.full_name": {
                "asc": {
                    "users.last_name": 4
                },
                "desc": {
                    "users.last_name": 3
                }
            },
            "user.company": {
                "asc": {
                    "users.company": 4
                },
                "desc": {
                    "users.company": 3
                }
            },
            "user.subdivision": {
                "asc": {
                    "users.subdivision": 4
                },
                "desc": {
                    "users.subdivision": 3
                }
            },
            "user.position": {
                "asc": {
                    "users.position": 4
                },
                "desc": {
                    "users.position": 3
                }
            }
        },
        "defaultOrder": {
            "user.full_name": 4
        }
    }
}